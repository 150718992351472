import { Typography } from '@pickupp/ui/core'
import React from 'react'

import Block from '~/src/components/Block'
import Page from '~/src/components/Page'
import { useTranslation } from '~/src/hooks'

const NotFoundPage = () => {
  const t = useTranslation()

  return (
    <Page>
      <Block>
        <Typography
          component="div"
          html={t('error.404')}
          style={{ textAlign: 'center' }}
        />
      </Block>
    </Page>
  )
}

export default NotFoundPage
